import { SocialDiscordIcon } from "~/components/SocialDiscordIcon";
import { SocialXIcon } from "~/components/SocialXIcon";

export const Footer = ({ expanded }: { expanded: boolean }) => {
  return (
    <div className="w-full flex gap-2 items-center justify-between bg-[#1C1C1F] rounded-2xl p-4 flex-row">
      <img className="w-7" src={"/assets/images/logo/yellow.png"} alt="" />
      {expanded && (
        <>
          <div className="text-[#fdfdfd] text-lg font-bold font-['Inter'] leading-[18px] flex-grow">
            Ninjalerts
          </div>
          {[
            { Icon: SocialXIcon, href: "https://x.com/ninjalerts" },
            { Icon: SocialDiscordIcon, href: "https://discord.com/invite/pizzaninjas" },
          ].map(({ Icon, href }, index) => (
            <a href={href} target="_blank" key={index}>
              <div className="w-8 h-8 rounded-lg flex justify-center items-center cursor-pointer bg-[#161618] hover:bg-black">
                <Icon />
              </div>
            </a>
          ))}
        </>
      )}
    </div>
  );
};
