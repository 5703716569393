import { useParams } from "@remix-run/react";
import { Account } from "./components/Account";
import { useState } from "react";
import { BoardActions } from "./components/BoardActions";
import { Boards } from "./components/Boards";
import { cn } from "~/utils/cn";
import { Footer } from "./components/Footer";

export type LeftMenuUser = {
  email: string;
  boards: {
    _id: string;
    name: string;
    emojiId?: string | null;
    order: number;
    newAlertsCount: number;
  }[];
};

export const LeftMenuDesktop = ({ user }: { user: LeftMenuUser }) => {
  const params = useParams();
  const currentBoardId = params.boardId;

  const board = user.boards.find((v) => v._id === currentBoardId);

  const [expanded, setExpanded] = useState(true);

  return (
    <div
      className={cn(
        "h-full flex flex-col md:items-center 2xl:items-stretch gap-2 mr-2 w-[260px]",
        expanded ? "w-[260px]" : "w-[48px]"
      )}
    >
      <Account email={user.email} setExpanded={() => setExpanded(!expanded)} expanded={expanded} />
      {board && <BoardActions board={board} expanded={expanded} />}
      <Boards user={user} currentBoardId={currentBoardId} expanded={expanded} />
      <Footer expanded={expanded} />
    </div>
  );
};

export const LeftMenuMobile = ({
  user,
  setMenuOpen,
}: {
  user: LeftMenuUser;
  setMenuOpen: (open: boolean) => void;
}) => {
  const params = useParams();
  const currentBoardId = params.boardId;

  return (
    <div className="h-full w-full flex flex-col md:items-center 2xl:items-stretch gap-2 mr-2">
      <Account
        email={user.email}
        setExpanded={() => {}}
        expanded
        isMobile
        setMenuOpen={setMenuOpen}
      />
      <Boards user={user} currentBoardId={currentBoardId} setMenuOpen={setMenuOpen} expanded />
      <Footer expanded />
    </div>
  );
};
