import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "~/components/Button";
import { TextInput } from "~/components/TextInput";
import { Loader2, Trash, X } from "lucide-react";
import { useFetcher } from "@remix-run/react";
import { EmojiPicker } from "~/components/EmojiPicker";
import { SettingIcon } from "~/components/SettingIcon";
import { Emoji } from "~/components/Emoji";

export const BoardSettings = ({
  board,
  setMenuOpen,
}: {
  board: { _id: string; name: string; emojiId?: string | null | undefined };
  setMenuOpen?: (open: boolean) => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const [emojiId, setEmojiId] = useState<string | null>(board.emojiId ?? null);
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [open]);

  const fetcher = useFetcher<any>();
  useEffect(() => {
    if (fetcher.state === "submitting") setOpen(false);
  }, [fetcher.state]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  return (
    <>
      <Dialog.Root
        open={open}
        onOpenChange={(isOpen) => {
          setOpen(isOpen);
          if (!isOpen) setConfirmDelete(false);
        }}
      >
        <Dialog.Trigger asChild>
          <Button
            variant={"transparent"}
            size="icon"
            className="w-8 h-8 rounded-lg touch-none bg-[#161618]"
            onClick={() => {
              if (setMenuOpen) setMenuOpen(false);
            }}
          >
            <SettingIcon />
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-black/60 fixed inset-0 p-4 flex items-center justify-center z-20 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade">
            <Dialog.Content className="w-full max-w-lg bg-primary-foreground rounded-xl p-5 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <Dialog.Title className="text-[#fdfdfd] text-lg font-semibold font-['Inter'] leading-[18px]">
                  {confirmDelete ? "Delete Board" : "Edit Board"}
                </Dialog.Title>
                <Dialog.Close asChild>
                  <Button
                    variant="default"
                    className="flex items-center justify-center rounded-lg w-8 h-8 text-black p-0"
                    size="default"
                  >
                    <X className="w-4 h-4 text-black" strokeWidth={3} />
                  </Button>
                </Dialog.Close>
              </div>

              {confirmDelete ? (
                <>
                  <div className="text-white">
                    This action is NOT reversible. It will delete all data including columns.
                  </div>
                  <div className="flex justify-between gap-4">
                    <Button
                      variant={"transparent"}
                      size={"xl"}
                      className="w-full h-12 py-4 bg-[#27272b] rounded-lg justify-center items-center gap-2 inline-flex"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <div className="text-[#fdfdfd] text-base font-semibold font-['Inter'] leading-none">
                        Cancel
                      </div>
                    </Button>
                    <Button
                      variant={"destructive"}
                      size={"xl"}
                      className="w-full h-12 py-4 bg-[#f84672]/5 rounded-lg justify-center items-center gap-2 inline-flex"
                      onClick={(e) => {
                        e.preventDefault();
                        fetcher.submit(
                          { _action: "board-delete", boardId: board._id },
                          { method: "POST", action: "/boards" }
                        );
                      }}
                    >
                      <Trash className="text-[#f84672] w-4 h-4" strokeWidth={3} />
                      <div className="text-[#f84672] text-base font-semibold font-['Inter'] leading-none">
                        Confirm Board Deletion
                        {fetcher.state !== "idle" && (
                          <Loader2 className="ml-1 h-4 w-4 animate-spin" />
                        )}
                      </div>
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex gap-4 w-full">
                    <div className="bg-[#161618] rounded-lg w-12 h-12 flex items-center justify-center">
                      <Emoji emojiId={emojiId} />
                    </div>
                    <TextInput
                      type="text"
                      placeholder="Name"
                      className="px-4 py-[10px] w-full rounded-xl mb-2"
                      defaultValue={board.name}
                      ref={ref}
                      name="name"
                    />
                  </div>
                  <EmojiPicker onEmojiPicked={setEmojiId} />

                  <div className="flex justify-between gap-4">
                    <Button
                      variant={"transparent"}
                      size={"xl"}
                      className="w-full h-12 py-4 bg-[#27272b] rounded-lg justify-center items-center gap-2 inline-flex"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <div className="text-[#fdfdfd] text-base font-semibold font-['Inter'] leading-none">
                        Cancel
                      </div>
                    </Button>
                    <Button
                      variant={"default"}
                      size={"lg"}
                      className="w-full h-12 py-4 bg-[#ffd400] rounded-lg justify-center items-center gap-2 inline-flex"
                      onClick={(e) => {
                        e.preventDefault();
                        fetcher.submit(
                          {
                            name: ref.current?.value!,
                            emojiId,
                            boardId: board._id,
                            _action: "board-edit",
                          },
                          { action: "/boards", method: "POST" }
                        );
                      }}
                    >
                      <div className="text-[#161618] text-base font-semibold font-['Inter'] leading-none">
                        Update
                      </div>
                    </Button>
                  </div>
                  <Button
                    variant={"destructive"}
                    size={"xl"}
                    className="w-full h-12 py-4 bg-[#f84672]/5 rounded-lg justify-center items-center gap-2 inline-flex"
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmDelete(true);
                    }}
                  >
                    <Trash className="text-[#f84672] w-4 h-4" strokeWidth={3} />
                    <div className="text-[#f84672] text-base font-semibold font-['Inter'] leading-none">
                      Delete
                    </div>
                  </Button>
                </>
              )}
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
