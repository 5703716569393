import * as Dialog from "@radix-ui/react-dialog";
import { Button } from "~/components/Button";
import { TextInput } from "~/components/TextInput";
import { useFetcher } from "@remix-run/react";
import { useEffect, useRef, useState } from "react";
import { useNewBoard } from "../../../contexts/NewBoardContext";
import { Plus, X } from "lucide-react";
import { EmojiPicker } from "~/components/EmojiPicker";
import { cn } from "~/utils/cn";
import { Emoji } from "~/components/Emoji";
import { SubTitle } from "~/components/SubTitle";

export const BoardCreate = ({
  setMenuOpen,
  expanded,
}: {
  setMenuOpen?: (open: boolean) => void;
  expanded: boolean;
}) => {
  const fetcher = useFetcher<any>();
  const { showCreateBoard, setShowCreateBoard } = useNewBoard();
  const [emojiId, setEmojiId] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (showCreateBoard) {
      setEmojiId(null);
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  }, [showCreateBoard]);
  useEffect(() => {
    if (fetcher.state === "submitting") setShowCreateBoard(false);
  }, [fetcher.state]);

  return (
    <>
      <Dialog.Root open={showCreateBoard} onOpenChange={setShowCreateBoard}>
        <Dialog.Trigger asChild>
          <Button
            className={cn(
              expanded ? "py-2 px-2.5" : "p-2",
              "rounded-lg flex justify-center items-center cursor-pointer bg-[#161618] hover:bg-black gap-1"
            )}
            variant={"transparent"}
            size={"xs"}
            onClick={() => {
              if (setMenuOpen) setMenuOpen(false);
            }}
          >
            <Plus className="w-4 h-4 text-primary" strokeWidth={3.5} />
            {expanded && (
              <div className="[font-family:'Inter-Medium',Helvetica] font-medium text-[#fdfdfd] text-sm tracking-[-0.08px] leading-4">
                New Board
              </div>
            )}
          </Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="bg-black/60 fixed inset-0 p-4 flex items-center justify-center z-20 will-change-[opacity] data-[state=open]:animate-slideDownAndFade">
            <Dialog.Content className="w-full max-w-lg bg-primary-foreground rounded-xl p-5 will-change-[transform,opacity] data-[state=open]:animate-slideDownAndFade">
              <fetcher.Form method="POST" className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                  <Dialog.Title className="text-[#fdfdfd] text-lg font-semibold font-['Inter'] leading-[18px]">
                    Create a Board
                  </Dialog.Title>
                  <Dialog.Close asChild>
                    <Button
                      variant="default"
                      className="flex items-center justify-center rounded-lg w-8 h-8 text-black p-0"
                      size="default"
                    >
                      <X className="w-4 h-4 text-black" strokeWidth={3} />
                    </Button>
                  </Dialog.Close>
                </div>
                <div className="flex gap-4 w-full">
                  <div className="bg-[#161618] rounded-lg w-12 h-12 flex items-center justify-center">
                    <Emoji emojiId={emojiId} />
                  </div>
                  <TextInput
                    type="text"
                    placeholder="Name"
                    name="name"
                    className="px-4 py-[10px] flex-grow rounded-xl h-12"
                    ref={ref}
                  />
                </div>

                <EmojiPicker onEmojiPicked={setEmojiId} />

                {emojiId && <input type="hidden" name="emojiId" value={emojiId} />}

                <div className="flex justify-between pt-2 gap-4">
                  <Button
                    variant={"transparent"}
                    size={"xl"}
                    className="w-full h-12 py-4 bg-[#27272b] rounded-lg justify-center items-center gap-2 inline-flex"
                    onClick={() => setShowCreateBoard(false)}
                  >
                    <div className="text-[#fdfdfd] text-base font-semibold font-['Inter'] leading-none">
                      Cancel
                    </div>
                  </Button>
                  <Button
                    variant={"default"}
                    size={"lg"}
                    type="submit"
                    name="_action"
                    value="board-create"
                    className="w-full h-12 py-4 bg-[#ffd400] rounded-lg justify-center items-center gap-2 inline-flex"
                  >
                    <div className="text-[#161618] text-base font-semibold font-['Inter'] leading-none">
                      Create
                    </div>
                  </Button>
                </div>
              </fetcher.Form>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
